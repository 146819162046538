import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { useRouteConfiguration } from '../../../../context/routeConfigurationContext.js';
import { matchPathname } from '../../../../util/routes.js';

import { NamedLink, ExternalLink } from '../../../../components/index.js';
import css from './Link.module.css';
import toast, { Toaster } from 'react-hot-toast';

export const Link = React.forwardRef((props, ref) => {
  const location = useLocation();
  const routes = useRouteConfiguration();

  const { className, rootClassName, href, title, children } = props;
  const classes = classNames(rootClassName || css.link, className);
  const titleMaybe = title ? { title } : {};
  const linkProps = { className: classes, href, children, ...titleMaybe };

  // Markdown parser (rehype-sanitize) might return undefined href
  if (!href || !children) {
    return null;
  }

  if (href.charAt(0) === '/') {
    // Internal link
    const testURL = new URL('http://my.marketplace.com' + href);
    const matchedRoutes = matchPathname(testURL.pathname, routes);
    if (matchedRoutes.length > 0) {
      const found = matchedRoutes[0];
      const to = { search: testURL.search, hash: testURL.hash };
      return (
        <>
          {href.includes('/s') || href.includes('/l/new') ? (
            <div
              onClick={() => {
                toast.dismiss();
                toast(
                  'Our developers are dedicated to creating our website and we truly appreciate your patience. Please bookmark this page—we’re excited to welcome you back!',
                  {
                    style: {
                      backgroundColor: '#05ed15',
                      height: '100%',
                      color: '#fff',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      padding: '16px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                    duration: 5000,
                    position: 'top-center',
                    icon: '🚀',
                  }
                );
              }}
              className={css.customLink}
            >
              {children[0]}
            </div>
          ) : (
            <NamedLink
              name={found.route.name}
              params={found.params}
              to={to}
              {...linkProps}
              ref={ref}
            />
          )}
        </>
      );
    }
  }

  if (href.charAt(0) === '#') {
    if (typeof window !== 'undefined') {
      const hash = href;
      let testURL = new URL(
        `http://my.marketplace.com${location.pathname}${location.hash}${location.search}`
      );
      testURL.hash = hash;
      const matchedRoutes = matchPathname(testURL.pathname, routes);
      if (matchedRoutes.length > 0) {
        const found = matchedRoutes[0];
        const to = { search: testURL.search, hash: testURL.hash };
        return (
          <NamedLink
            name={found.route.name}
            params={found.params}
            to={to}
            {...linkProps}
            ref={ref}
          />
        );
      }
    }
  }

  return (
    <>
      <ExternalLink {...linkProps} ref={ref} />;
      <Toaster />
    </>
  );
});

Link.displayName = 'Link';

Link.defaultProps = {
  rootClassName: null,
  className: null,
};

Link.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
  href: string.isRequired,
};
