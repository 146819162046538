import React from 'react';
import css from './GolfCommunities.module.css';
import slide1 from '../../../../../assets/1.png';
import slide2 from '../../../../../assets/2.png';
import slide3 from '../../../../../assets/3.png';
import slide4 from '../../../../../assets/4.png';

const golfDetail = [
  {
    image: slide1
  },
  {
    image: slide2
  },
  {
    image: slide3
  },
  {
    image: slide4
  },
]


function GolfCommunities() {
  return (
    <div className={css.golfBoard}>
      <div className={css.golfHead}>
        <h2>Golf Cart Communities</h2>
        <p>Find the right golf cart, book it and pay with your credit card. That's it!</p>
      </div>
      <div className={css.golfGrid}>
        {golfDetail.map((item, i) => {
          return (
            <div
              key={i}
              className={css.golfCard}
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <h5 className={css.golfCartHeading}>
                Post a new Golf Cart
              </h5>
              <div className={css.bottomButton}>
                <button>Post a new Golf Cart</button>
                <button>Rent a Cart</button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GolfCommunities
